<template>
  <div class="bm-menu" :style="width">
    <div class="p-2">
      <div class="text-right p-3">
        <font-awesome-icon
          class="iconeFechar"
          @click="closeMenu()"
          icon="times"
        />
      </div>
      <h4 class="m-2">Olá, {{ formataNome(decoded.nome) }}</h4>

      <hr />
      <nav class="bm-item-list">
        <router-link :to="'/dashboard'">
          <i class="fa fa-fw fa-star-o mb-3"></i>
          <span>Dashboard</span>
        </router-link>
        <router-link v-if="decoded.tipo == 'Associado'" :to="'/meusDados'">
          <i class="fa fa-fw fa-user-o mb-3"></i>
          <span>Meus Dados</span>
        </router-link>

        <router-link v-if="decoded.tipo == 'Associado'" :to="'/FormaPagamento'">
          <i class="fa fa-fw fa-credit-card mb-3"></i>
          <span>Forma de Pagamento</span>
        </router-link>

        <div v-if="decoded.tipo !== 'Associado'" @click="irParaPreCotacao()">
          <i class="fa fa-fw fa-clock-o mb-3"></i>
          <span>Pré Cotação</span>
        </div>

        <!--router-link v-if="decoded.id_empresa == 4" :to="`/testeCamera`">
          <i class="fa fa-fw fa-power-off"></i>
          <span>Teste Camera 1 </span>
        </router-link>
        <a
          v-if="decoded.id_empresa == 4"
          :href="`https://eloquent-kowalevski-0dbda6.netlify.app/`"
        >
          <i class="fa fa-fw fa-power-off"></i>
          <span>Teste Camera 2 </span>
        </a-->
        <router-link v-if="decoded.id_empresa == 4" :to="`/testeCamera3`">
          <i class="fa fa-fw fa-power-off mb-3"></i>
          <span>Teste Camera 3 </span>
        </router-link>
        <!-- <router-link :to="`/?idEmpresa=${decoded.id_empresa}`"> -->
        <div @click="logout()">
          <i class="fa fa-fw fa-power-off mb-3"></i>
          <span>Sair</span>
        </div>
        <!-- </router-link> -->
      </nav>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import { getIdEmpresaDefault } from './../services/multiple-empresa';
import { logout } from './../services/logout';

export default {
  name: 'DashBoard',
  props: {
    openMenuLateral: Boolean,
  },
  watch: {
    openMenuLateral() {
      if (this.openMenuLateral == true) this.openMenu();
    },
  },
  data() {
    return {
      width: { width: '0px' },
      decoded: {},
      rota: null,
    };
  },
  methods: {
    formataNome(nome) {
      var aux = '';
      if (nome) aux = nome.split(' ')[0];
      return aux;
    },
    openMenu() {
      this.width.width = '90%';
    },
    closeMenu() {
      this.width.width = '0px';
      this.$emit('closeMenuLateral', true);
    },
    verificaToken() {
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
      }
    },
    irParaPreCotacao() {
      this.rota !== 'dashboard' && this.$router.replace(`/dashboard?page=4`);
      this.$emit('cotacaoRapida');
    },
    verificarRota() {
      this.rota = this.$route.name;
    },
    logout() {
      logout();
    },
  },
  mounted() {
    this.verificaToken();
    this.verificarRota();
    // this.$router.replace(`/dashboard?page=4`)
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  overflow-x: hidden;
}

.iconeFechar {
  font-size: 28px;
}
.bm-menu {
  background: #373a47;
}
.bm-menu {
  background-color: #3f3f41;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: 0.2s;
  width: 0;
  z-index: 9999;
  color: #fff;
}
.bm-item-list > * {
  display: flex;
  padding: 0.7em;
  text-decoration: none;
}
.bm-item-list > * > span {
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
.bm-item-list > * > i {
  color: #666;
  font-weight: 700;
  margin-left: 10px;
}
.logoBottom {
  margin-top: 100px;
  text-align: center;
  opacity: 0.5;
}
</style>
